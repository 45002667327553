import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VRow,[(_vm.showButtons.cancel)?_c(VCol,{staticStyle:{"text-align":"right"},attrs:{"cols":"6","lg":"6","md":"6","sm":"6"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mx-2",staticStyle:{"background-color":"var(--color__red) !important","color":"#ffffff !important"},on:{"click":_vm.cancel}},on),[_vm._v(" Cancel ")])]}}],null,false,1952719143)},[_c('span',[_vm._v("Cancel")])])],1):_vm._e(),_c(VCol,{style:(_vm.showButtons.cancel ? 'text-align: left' : 'text-align: center'),attrs:{"cols":_vm.showButtons.cancel ? '6' : '12',"lg":_vm.showButtons.cancel ? '6' : '12',"md":_vm.showButtons.cancel ? '6' : '12',"sm":_vm.showButtons.cancel ? '6' : '12'}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.showButtons.save)?_c(VBtn,_vm._g({staticClass:"mx-2",attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.save(_vm.request)}}},on),[_vm._v(" Save ")]):_vm._e()]}}])},[_c('span',[_vm._v("Save")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }